var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-first-password"},[(!_vm.success)?_c('v-card',{staticClass:"change-first-password__card",attrs:{"elevation":"2"}},[_c('h1',{staticClass:"change-first-password__card-title"},[_vm._v(" Establecer nueva contraseña ")]),_c('p',{staticClass:"change-first-password__card-subtitle"},[_vm._v("Como es la primera vez que inicias sesión en la plataforma promocional de PharmaCall, debes cambiar la contraseña que te hemos generado por defecto por una que tú elijas.")]),_c('validation-observer',{ref:"observer"},[_c('form',{staticClass:"change-first-password__card__form",on:{"submit":function($event){$event.preventDefault();return _vm.changePassword($event)}}},[_c('v-container',[_c('validation-provider',{attrs:{"name":"Nueva contraseña","rules":{
                            required: true,
                            regex: '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$'
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nueva contraseña","outlined":"","error-messages":errors,"type":"password","dense":"","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,false,3576008411)}),_c('validation-provider',{attrs:{"name":"Repetir contraseña","rules":{
                            required: true,
                            samePass: _vm.password
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Repetir contraseña","outlined":"","error-messages":errors,"type":"password","dense":"","required":""},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})]}}],null,false,2684136808)}),(_vm.errorMsg != null)?_c('p',{staticClass:"change-first-password__card__form-error"},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e(),_c('div',{staticClass:"change-first-password__card__form-button"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" Cambiar contraseña ")])],1)],1)],1)])],1):_c('v-card',{staticClass:"change-first-password__card",attrs:{"elevation":"2"}},[_c('h1',{staticClass:"change-first-password__card-title"},[_vm._v(" ¡Contraseña cambiada correctamente! ")]),_c('p',{staticClass:"change-first-password__card-subtitle"},[_vm._v("La contraseña ha sido actualizada con éxito. Ya puedes iniciar sesión con tu nueva contraseña a partir de ahora.")]),_c('div',{staticClass:"change-first-password__card-button"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","to":"/"}},[_vm._v(" Entrar ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }