<template>
    <div class="change-first-password">
        <v-card
            elevation="2"
            class="change-first-password__card"
            v-if="!success"
            >
            <h1 class="change-first-password__card-title">
                Establecer nueva contraseña
            </h1>
            <p class="change-first-password__card-subtitle">Como es la primera vez que inicias sesión en la plataforma promocional de PharmaCall, debes cambiar la contraseña que te hemos generado por defecto por una que tú elijas.</p>
            <validation-observer
                ref="observer"
            >
                <form class="change-first-password__card__form" @submit.prevent="changePassword">
                    <v-container>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Nueva contraseña"
                            :rules="{
                                required: true,
                                regex: '^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$'
                            }"
                        >
                            <v-text-field
                                    label="Nueva contraseña"
                                    outlined
                                    v-model="password"
                                    :error-messages="errors"
                                    type="password"
                                    dense
                                    required
                                ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Repetir contraseña"
                            :rules="{
                                required: true,
                                samePass: password
                            }"
                        >
                            <v-text-field
                                label="Repetir contraseña"
                                outlined
                                v-model="repeatPassword"
                                :error-messages="errors"
                                type="password"
                                dense
                                required
                            ></v-text-field>
                        </validation-provider>
                        <p v-if="errorMsg != null" class="change-first-password__card__form-error">{{ errorMsg }}</p>
                        <div class="change-first-password__card__form-button">
                            <v-btn
                                depressed
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                >
                                Cambiar contraseña
                            </v-btn>
                        </div>
                    </v-container>
                </form>
            </validation-observer>
        </v-card>
        <v-card
            elevation="2"
            class="change-first-password__card"
            v-else
            >
            <h1 class="change-first-password__card-title">
                ¡Contraseña cambiada correctamente!
            </h1>
            <p class="change-first-password__card-subtitle">La contraseña ha sido actualizada con éxito. Ya puedes iniciar sesión con tu nueva contraseña a partir de ahora.</p>
            <div class="change-first-password__card-button">
                <v-btn
                    depressed
                    color="primary"
                    to="/"
                    >
                    Entrar
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
import { required, regex } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from 'vee-validate';
import httpService from '@/services/httpService'

setInteractionMode('eager');

extend('required', {
    ...required,
    message: 'El campo es requerido',
  });

extend('regex', {
    ...regex,
    message: 'La contraseña debe tener como mínimo 8 caracteres y debe incluir al menos una mayúscula, una minúscula y un número',
});

const samePass = (value, password) => {
  return value == String(password);
};

extend('samePass', samePass, 'password');

extend('samePass', {
    message: 'Las contraseñas deben coincidir'
});

export default {
  name: 'ChangeFirstPassword',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
      return {
        loading: false,
        password: null,
        repeatPassword: null,
        errorMsg: null,
        success: false
      }
  },
  methods: {
        changePassword() {
            this.errorMsg = null;
            this.loading = true;
            this.$refs.observer.validate().then((valid) => {
                if (valid) {
                    httpService.post('/auth/reset-password', {
                        password: this.password
                    }).then(() => {
                        this.success = true;
                        this.loading = false;
                    }).catch(() => {
                        this.errorMsg = 'Se ha producido un error al actualizar la contraseña. Inténtalo de nuevo en unos minutos.';
                        this.loading = false;
                    })
                } else {
                    this.loading = false;
                }
            })
        }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/variables';

    .change-first-password {
        height: calc(100vh - #{$header_size_mobile});
        margin-top: $header_size_mobile;
        background-color: #f7f7f7;
        @media (min-width: 960px) { 
            height: calc(100vh - #{$header_size_desktop});
            margin-top: $header_size_desktop;
        }
        &__card {
            margin: 20px auto;
            width: 95%;
            max-width: 600px;
            color: $text_color !important;
            @media (min-width: 960px) { 
                margin: 35px auto;
            }
            &-title {
                color: $text_color;
                font-weight: 400 !important;
                font-size: 1.8em;
                text-align: center;
                padding: 15px 0 10px 0;
            }
            &-subtitle {
                text-align: left;
                color: $text_color;
                font-weight: 400 !important;
                margin: 0 40px;
            }
            &-button {
                display: flex;
                a {
                    margin: 20px auto;
                }
            }
            &__form {
                padding: 10px 20px 20px 20px;
                &-button {
                    display: flex;
                    button {
                        margin: auto;
                    }
                }
                &-error {
                    color: #f44336;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
    }
</style>
